/* eslint-disable no-console */

if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.BASE_URL}ocean-service-worker.js`)
    .then(registration => {
      console.log("Service worker registered with scope:", registration.scope);

      registration.addEventListener("updatefound", () => {
        const installingWorker = registration.installing;
        console.log("New service worker is being installed:", installingWorker);

        installingWorker.addEventListener("statechange", () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("New content is available; please refresh.");
            } else {
              console.log("Content is cached for offline use.");
            }
          }
        });
      });
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });

  navigator.serviceWorker.ready.then(registration => {
    console.log("Service Worker ready with scope:", registration.scope);
  });

  // Listen for the 'offline' event to handle offline mode
  window.addEventListener("offline", () => {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  });
}
