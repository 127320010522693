<template>
  <div v-if="!sized" class="loginView"></div>

  <div v-if="showInstallPopup" class="install-popup">
    <!-- iOS-specific installation message -->
    <p v-if="isIOS">
      To install this app on your iPhone or iPad, tap the
      <strong>Share</strong> icon at the bottom of Safari's screen (it looks
      like a square with an arrow pointing up), then select
      <strong>Add to Home Screen</strong>.
    </p>

    <!-- Standard PWA installation message for other platforms -->
    <p v-else>
      To install this as an app on your device, click below or click the icon on
      your browser's nav bar:
    </p>

    <button
      @click="promptForInstall"
      v-if="deferredPrompt && !isIOS"
      class="install-button"
    >
      Install App
    </button>

    <p v-else-if="!deferredPrompt && !isIOS">
      Click the install icon (plus symbol) in your browser's address bar to
      install the app.
    </p>

    <button
      @click="dismissInstallPopup"
      class="close-popup-button"
      style="margin-left: 10px"
    >
      Hide
    </button>
  </div>

  <div class="loginView">
    <div class="login-form mt-1">
      <div class="section">
        <img
          src="img/logo.png"
          alt="icon"
          class="form-image"
          style="margin-top: 50px"
        />
      </div>
      <div class="section mt-1">
        <h1>Log In</h1>
        <h5>v{{ appVersion }}</h5>
      </div>
      <div class="section mt-1 mb-5">
        <form action="app-pages.html">
          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                type="email"
                class="form-control"
                id="email1"
                placeholder="Email address"
                v-model="email"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
              <p v-if="emailError" id="emailMessage" class="text-danger">
                {{ emailError }}
              </p>
            </div>
          </div>

          <div class="form-group boxed">
            <div class="input-wrapper">
              <input
                class="form-control"
                id="password1"
                placeholder="Password"
                autocomplete="off"
                v-model="password"
              />
              <i class="clear-input">
                <ion-icon name="close-circle"></ion-icon>
              </i>
            </div>
          </div>

          <div class="form-links mt-2">
            <div>
              <a href="javascript:void(0)" @click="goToRegister"
                >Register Now</a
              >
            </div>
          </div>

          <!--Settings-->
          <div class="form-group boxed" style="margin-top: 30px">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg btn-ocean-action"
              @click="login"
              @touchstart="login"
            >
              Log in
            </button>
          </div>
        </form>
      </div>

      <div class="section mt-1">
        <h1>Disclaimer</h1>
        <h4>
          This is NOT a navigation app and is for informational purposes only
        </h4>
      </div>

      <div class="form-group boxed" style="margin-top: 30px" hidden>
        <button
          type="button"
          class="btn btn-danger btn-block btn-sm"
          @click="clearAllData"
        >
          Clear All Data
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/db";
import CryptoJS from "crypto-js";
import { mapState, mapMutations } from "vuex";
import { authService } from "@/api/auth";

export default {
  data() {
    return {
      email: null,
      password: null,
      emailError: "",
      sized: false,
      key: null,
      appVersion: process.env.VUE_APP_APP_VERSION,
      showInstallButton: false,
    };
  },
  computed: {
    ...mapState(["deferredPrompt", "showInstallPopup"]),
    isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
  },
  async created() {
    try {
      if (!db.isOpen()) {
        await db.open();
      }

      // Check if a token exists in local storage
      const storedToken = localStorage.getItem("token");

      if (storedToken) {
        this.key = storedToken; // Optionally set the token value
      }

      const id = 1;
      const settings = await db.Settings.get(id);

      if (settings && this.getLoggedInFlag()) {
        this.sized = true;
        this.$router.push("/main/map");
      }
    } catch (error) {
      console.error("Failed to fetch settings:", error);
    }
  },
  mounted() {
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Check if the app is already installed on iOS (standalone mode)
    const isStandalone = window.navigator.standalone;

    // If on iOS and not in standalone mode, show the custom install popup
    if (isIOS && isSafari && !isStandalone) {
      this.setShowInstallPopup(true); // Show custom popup for iOS users to add to home screen
    } else {
      // Standard PWA install prompt handling for other devices
      window.addEventListener("beforeinstallprompt", event => {
        event.preventDefault();
        this.$store.commit("setDeferredPrompt", event);
        this.setShowInstallPopup(true);
      });
    }
  },

  methods: {
    ...mapMutations(["setShowInstallPopup", "setDeferredPrompt"]),

    promptForInstall() {
      if (this.deferredPrompt) {
        // Show the install prompt
        this.deferredPrompt.prompt();

        // Handle the user's response to the install prompt
        this.deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
          // Clear the prompt so it doesn't trigger again
          this.setDeferredPrompt(null);
          this.setShowInstallPopup(false);
        });
      }
    },

    dismissInstallPopup() {
      this.setShowInstallPopup(false);
      // Optionally, store dismissal to avoid re-prompting during the session
      localStorage.setItem("installDismissed", "true");
    },
    async clearAllData() {
      try {
        // List all object stores (tables) in your database
        const tables = db.tables;

        // Loop through each table and clear it
        for (const table of tables) {
          await table.clear();
          console.log(`Cleared all records from table: ${table.name}`);
        }

        // Clear other stored data
        localStorage.removeItem("token");
        console.log("LocalStorage cleared.");

        // Unregister the service worker to reset the app completely
        this.resetApp();

        // Redirect to the login page after a short delay
        setTimeout(() => {
          window.location.href = "https://ocean.evolve-red.com/"; // You can change this to your desired route
        }, 1000);
      } catch (error) {
        console.error("Error clearing data:", error);
        alert("An error occurred while clearing data.");
      }
    },
    resetApp() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          for (let registration of registrations) {
            registration.unregister().then(() => {
              console.log("Service worker unregistered. Reloading...");
              //window.location.reload(); // Reload to start fresh
            });
          }
        });
      }
    },
    hashPassword(password) {
      return CryptoJS.SHA256(password).toString();
    },
    async getLoggedInFlag() {
      try {
        const encryptedFlag = await db.Settings.get("Sized");
        if (encryptedFlag) {
          const secretKey = "the--key!";
          const bytes = CryptoJS.AES.decrypt(encryptedFlag, secretKey);
          const decryptedFlag = bytes.toString(CryptoJS.enc.Utf8);
          return stringToBoolean(decryptedFlag);
        }
        return ""; // Default if no flag is found
      } catch (error) {
        console.error("Error fetching the Sized flag:", error);
        return ""; // Default if an error occurs
      }
    },
    goToRegister() {
      this.$router.push("/main/register");
    },
    validateEmail() {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = "Email address is required";
        return false;
      } else if (!re.test(this.email)) {
        this.emailError = "Please enter a valid email address";
        return false;
      }
      this.emailError = "";
      return true;
    },
    async login() {
      // Check for internet connection before proceeding
      if (!navigator.onLine) {
        this.loginError =
          "No internet connection. Please check your network and try again.";
        alert(this.loginError);
        return;
      }
      debugger;
      console.log("Login button clicked");
      if (!this.validateEmail()) {
        return;
      }

      console.log("open DB");
      try {
        if (!db.isOpen()) {
          await db.open();
        }
        console.log("DB OPen");

        const formData = new FormData();
        formData.append("src", this.email.trim());
        formData.append("pwd", this.password.trim());
        formData.append("utype", "O");

        const response = await fetch(
          process.env.VUE_APP_OCEAN_API_BASE_URL + "registerUserV4",
          {
            method: "POST",
            body: formData,
            credentials: "include",
          }
        );
        debugger;
        const data = await response.json();
        console.log("Data fetched " + data[0]);
        if (data[0] === "W001") {
          //User already registered and confirmed
          console.log("W001");

          // Login to Backend
          // Call the login API to set the cookie
          const loginResult = await authService.login(
            this.email.trim(),
            this.password.trim()
          );

          console.log("Login message:", loginResult.message);
          if (loginResult.success) {
            console.log("Login successful:", loginResult.message);

            // Save to IndexedDB
            const id = 1;
            let settings = await db.Settings.get(id);

            if (settings) {
              await db.Settings.update(id, {
                Email: this.email.trim(),
                Sized: true,
                Token: loginResult.key,
              });
            } else {
              await db.Settings.add({
                SettingId: id,
                Email: this.email.trim(),
                Sized: true,
                Token: loginResult.key,
              });
            }
            console.log("Attempting to route to /main/map");
            this.$router.push("/main/map");
          } else {
            const loginResult2 = await authService.register(
              this.email.trim(),
              this.password.trim(),
              localStorage.getItem("token"),
              false
            );
            console.log(loginResult2.message);
            if (loginResult2.success) {
            } else {
              alert(
                "There seems to be a problem storing some access keys. If you cleared cache or reinstalled the app you may need to re-register."
              );
            }
          }
          debugger;
          this.$router.push("/main/map");
        } else if (data[0] === "W002") {
          alert("Please check your email to confirm your account. W002");
        } else if (data[0].startsWith("E")) {
          this.loginError = "Invalid credentials, please try again." + data[0];
          alert(this.loginError);
        }
      } catch (error) {
        console.error("Login failed:", error);
        this.loginError = navigator.onLine
          ? "An error occurred while logging in. Please try again."
          : "No internet connection. Please check your network and try again.";
        alert(this.loginError);
      }
    },
  },
};
</script>
<style scoped>
html {
  font-size: 20px !important;
}
.form-control {
  font-size: 16px;
}
.btn {
  border: 1px solid rgb(214, 214, 214);
}
.btn-primary {
}

#emailMessage {
  padding-top: 10px;
}

.install-popup {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  background: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 95%; /* Nearly full screen width */
  max-width: 600px; /* Optional: limit the width on larger screens */
  text-align: center;
  z-index: 1000;
  line-height: 1.2;
}

.install-button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #008cba;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-popup-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #aaa;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
</style>
