if (process.env.NODE_ENV === "production") {
  // Disable Vue DevTools in production

  devtools && (devtools.enabled = false);
}

import { createApp, devtools } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Create the Vue app
const app = createApp(App);

// Disable Vue DevTools in production
if (process.env.NODE_ENV === "production") {
  devtools && (devtools.enabled = false);
}

// Use Vue plugins and mount the app
app.use(store).use(router).mount("#app");

// Register the service worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", async () => {
    try {
      const registration = await navigator.serviceWorker.register(
        "/ocean-service-worker.js"
      );
      console.log(
        "ServiceWorker registration successful with scope: ",
        registration.scope
      );

      // Register Background Sync after the service worker is ready
      await registerBackgroundSync();
    } catch (err) {
      console.error("ServiceWorker registration failed:", err);
    }
  });
}

async function registerBackgroundSync() {
  if ("serviceWorker" in navigator && "SyncManager" in window) {
    try {
      // Ensure service worker is ready before registering the sync event
      const registration = await navigator.serviceWorker.ready;
      await registration.sync.register("ocean-sync-queue");
      console.log("Background sync successfully registered.");
    } catch (error) {
      console.error("Error registering background sync:", error);
    }
  } else {
    console.warn("Background sync is not supported in this browser.");
  }
}
