import { createRouter, createWebHistory } from "vue-router";
import Login from "@/components/Login2.vue";
import MainLayout from "@/components/MainLayout.vue"; // Ensure the correct path
import CryptoJS from "crypto-js";
import db from "@/db";

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/main",
    component: MainLayout,
    children: [
      {
        path: "map",
        component: () => import("@/views/Map.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "report",
        component: () => import("@/views/Report.vue"),
      },
      {
        path: "reportMammal",
        component: () => import("@/views/ReportMammal.vue"),
      },
      {
        path: "reportContainer",
        component: () => import("@/views/ReportContainer.vue"),
      },
      {
        path: "settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "viewData",
        component: () => import("@/views/ViewData.vue"),
      },
      {
        path: "viewDataLocal",
        component: () => import("@/views/ViewDataLocal.vue"),
      },
      {
        path: "register",
        component: () => import("@/views/Register2.vue"),
      },
    ],
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: () => import("@/views/ConfirmEmail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/",
});

// Global beforeEach guard to check if the user is authenticated
// Checked on every page and if not is redirected to root login
router.beforeEach(async (to, from, next) => {
  // Paths that don't require authentication
  const publicPaths = ["/", "/main/register", "/confirm-email"];

  if (!publicPaths.includes(to.path)) {
    // Check if the user is logged in
    const sizedFlag = await getLoggedInFlag();

    if (sizedFlag !== true) {
      // Redirect to login page if not authenticated
      return next("/");
    }
  }

  // Allow navigation for public paths or authenticated users
  next();
});

function stringToBoolean(str) {
  return str === "true";
}

async function getLoggedInFlag() {
  try {
    const Flag = await db.Settings.get(1);
    if (Flag.Sized) {
      return true; // Convert to boolean
    }
    return false; // Return false if no flag is found
  } catch (error) {
    console.error("Error fetching the Sized flag:", error);
    return false; // Return false if an error occurs
  }
}

export default router;
